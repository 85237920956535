import React from 'react';

interface ModalProps {
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({onClose}) => {
  return (
    <div className='relative'>
      <button
        onClick={onClose}
        className='absolute top-0 right-2 flex justify-center items-center w-8 h-8 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none focus:bg-gray-300 transition duration-300 z-50'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='w-4 h-4 text-gray-600'
          viewBox='0 0 20 20'
          fill='currentColor'>
          <path
            fillRule='evenodd'
            d='M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm5 13.59L13.59 15 10 11.41 6.41 15 5 13.59 8.59 10 5 6.41 6.41 5 10 8.59 13.59 5 15 6.41 11.41 10 15 13.59z'
            clipRule='evenodd'
          />
        </svg>
      </button>
    </div>
  );
};

export default Modal;
