import React, {Dispatch, SetStateAction} from 'react';
import CloseButton from './CloseButton';
interface ModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const TermsModal: React.FC<ModalProps> = ({isOpen, setIsOpen}) => {
  if (!isOpen) return null;
  return (
    <div className='flex justify-center h-screen bg-gray-500'>
      <div className='fixed z-10 inset-y-10 overflow-y-scroll justify-center w-11/12'>
        <div
          className='bg-white rounded-lg z-50 mt-0 pt-5 self-center w-auto'
          id='privacy-policy-terms-content'>
          <CloseButton onClose={() => setIsOpen(false)} />
          <div id='otnotice-a509f889-ddf7-4929-aab9-f8631d946c87' />
          <div id='otnotice-992457b6-7514-439d-8f42-847cdbdd8326' />
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
