import {useEffect} from 'react';

export const useOneTrustNotice = (url: string) => {
  useEffect(() => {
    const loadNotice = async () => {
      const scriptElement = document.createElement('script');
      scriptElement.type = 'text/javascript';
      scriptElement.innerHTML = `window.OneTrust.NoticeApi.Initialized.then(function () {
        window.OneTrust.NoticeApi.LoadNotices(['${url}']);
             });
          `;

      const modalDiv = document.getElementById('privacy-policy-terms-content');
      if (modalDiv) modalDiv.appendChild(scriptElement);
    };
    loadNotice();
    return () => {};
  }, [url]);
};
