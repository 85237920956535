import {useContext, useEffect, useState} from 'react';
import {Input} from '@material-tailwind/react';
import TermsModal from './components/TermsModal';
import {useOneTrustNotice} from './hooks/OneTrustNotice';
import {Context} from './AppWrapper';
import {FormattedMessage} from 'react-intl';
import {parseJwt, regexNameValidation} from './services';

function App() {
  const context = useContext<any>(Context);

  //OneTrust Vars & Hook
  const [isOpen, setIsOpen] = useState(false);
  const [oneTrustURL, setOneTrustURL] = useState<string>('');
  useOneTrustNotice(oneTrustURL);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<React.ReactNode>(null);
  const [userCountryCode, setUserCountryCode] = useState<string>('');
  //Form Vars
  const [formData, setFormData] = useState({
    givenname: '',
    familyname: '',
    termsAgreed: false,
    state: '',
    uri_state: '',
    session_token: '',
    subscription_source: 'BISSELL',
    subscribeEmail: true,
  });
  const [continueURI, setContinueURI] = useState<string>('');

  //hook to validate first & last name
  useEffect(() => {
    if (
      (formData.familyname && !regexNameValidation(formData.familyname)) ||
      (formData.givenname && !regexNameValidation(formData.givenname))
    )
      setErrorMessage(
        <FormattedMessage
          id='ErrorMsgs.patternMismatch'
          defaultMessage='Error'></FormattedMessage>,
      );
    else setErrorMessage(null);
  }, [formData.givenname, formData.familyname]);

  //reads session and state params
  useEffect(() => {
    const uri_state = new URLSearchParams(window.location.search).get('state');
    const sessionToken: string | null = new URLSearchParams(
      window.location.search,
    ).get('session_token');

    if (sessionToken) {
      const parsedJwt = parseJwt(sessionToken as string);
      const sessionTokenState = parsedJwt['state'];
      const sessionRedirectURL = parsedJwt['redirect_uri'];
      setUserCountryCode(parsedJwt['state'].match(/countryCode=([^&]*)/)[1]);
      setContinueURI(parsedJwt['continue_uri']);

      //setting locale
      const sessionLocale = parsedJwt['locale'];
      context.selectLanguage(sessionLocale);

      let tAgreement = false;
      // hide terms if it's not b.com
      if (!sessionRedirectURL.includes('Login-OAuthReentry')) {
        setIsMobile(true);
        tAgreement = true;
      }

      setFormData({
        ...formData,
        state: sessionTokenState,
        session_token: sessionToken as string,
        uri_state: uri_state as string,
        termsAgreed: tAgreement,
        subscription_source: tAgreement ? 'mobileApp' : 'BISSELL',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModal = (url: string) => {
    setOneTrustURL(url);
    setIsOpen(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const onNameBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;

    if (
      (formData.givenname && formData.givenname.length < 2) ||
      (formData.familyname && formData.familyname.length < 2)
    )
      setErrorMessage(
        <FormattedMessage id='ErrorMsgs.tooShort' defaultMessage='Error' />,
      );

    setFormData({...formData, [name]: value.replace(/\s+$/, '')});
  };

  const getInputIcon = (state: string, name: string) => {
    if (name.length > 0)
      return (
        <i
          className='far fa-times-circle fa-lg'
          aria-label='Click to clear name input field'
          style={
            (name.length > 0 && !regexNameValidation(name)) ||
            (name.length > 0 && name.length < 2)
              ? {color: '#d03c38'}
              : {color: 'gray'}
          }
          onClick={() => setFormData({...formData, [state]: ''})}
        />
      );
  };
  return (
    <>
      {!isOpen ? (
        <section className='flex justify-center'>
          <div className='self-center bg-white max-h-full font-montserrat'>
            <div className='container w-96'>
              <div className='flex flex-col items-center'>
                <img
                  className='place-items-center align-middle justify-items-center h-20 self-center mt-10'
                  id='prompt-logo-center'
                  src='https://www.bissell.com/on/demandware.static/Sites-bissell-Site/-/default/dwbe9a4202/svg-icons/logo-bissell.svg'
                  alt='logo'
                />
                <h1
                  className='mt-5 text-xl font-semibold text-black'
                  data-testid='welcome-heading'
                  id='welcome-heading'>
                  <FormattedMessage
                    id='WelcomeMsg'
                    defaultMessage='Welcome'
                    data-testid='WelcomeMsg'
                  />
                </h1>
                {errorMessage && (
                  <div className='bg-b-red2 p-2 rounded-md w-3/4 text-left m-5'>
                    <span className='text-white mb-5 text-base'>
                      {errorMessage}
                    </span>
                  </div>
                )}
              </div>

              <form
                method='POST'
                action={`${continueURI}?state=${formData.uri_state}`}
                acceptCharset='utf-8'>
                <div className='flex flex-col items-center space-y-10 pt-8'>
                  <div className='w-3/4'>
                    <FormattedMessage
                      id='GivenName'
                      defaultMessage='First Name'>
                      {str => (
                        <Input
                          name='givenname'
                          color='indigo'
                          size='lg'
                          min={2}
                          id='givenName'
                          data-testid='givenName'
                          label={str.toString()}
                          labelProps={{
                            'aria-label':
                              'Enter a first name for your BISSELL account',
                          }}
                          value={formData.givenname}
                          error={
                            (formData.givenname.length > 0 &&
                              !regexNameValidation(formData.givenname)) ||
                            (formData.givenname.length > 0 &&
                              formData.givenname.length < 2)
                          }
                          onChange={e => {
                            if (e.target.value[0] === ' ') return;
                            handleInputChange(e);
                          }}
                          icon={getInputIcon('givenname', formData.givenname)}
                          //trims white spaces at end & displays error message if name is too short.
                          onBlur={e => onNameBlur(e)}
                          required
                        />
                      )}
                    </FormattedMessage>
                  </div>
                  <div className='w-3/4'>
                    <FormattedMessage
                      id='FamilyName'
                      defaultMessage='Last Name'>
                      {str => (
                        <Input
                          data-testid='familyName'
                          name='familyname'
                          color='indigo'
                          size='lg'
                          label={str.toString()}
                          labelProps={{
                            'aria-label':
                              'Enter a last name for your BISSELL account',
                          }}
                          value={formData.familyname}
                          error={
                            (formData.familyname.length > 0 &&
                              !regexNameValidation(formData.familyname)) ||
                            (formData.familyname.length > 0 &&
                              formData.familyname.length < 2)
                          }
                          onChange={e => {
                            if (e.target.value[0] === ' ') return;
                            handleInputChange(e);
                          }}
                          icon={getInputIcon('familyname', formData.familyname)}
                          //trims white spaces at end & displays error message if name is too short.
                          onBlur={e => onNameBlur(e)}
                          required
                        />
                      )}
                    </FormattedMessage>
                  </div>

                  <div className='w-3/4'>
                    {(userCountryCode === 'US' || userCountryCode === 'CA') && (
                      <div className='flex items-center'>
                        <input
                          type='checkbox'
                          name='subscribeEmail'
                          data-testid='subscribeEmailId'
                          aria-label='Click to opt in to BISSELL email list.'
                          checked={formData.subscribeEmail}
                          value={formData.subscribeEmail.toString()}
                          onChange={() =>
                            setFormData({
                              ...formData,
                              subscribeEmail: !formData.subscribeEmail,
                            })
                          }
                          className='form-checkbox w-6 h-6 mr-2 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500'
                        />
                        <label className='ml-2 block text-sm text-gray-900'>
                          <span className='text-sm'>
                            <FormattedMessage
                              id='OptInMsg'
                              defaultMessage='Please add me to the BISSELL email list.'
                            />
                          </span>
                        </label>
                      </div>
                    )}
                    {isMobile === false ? (
                      <>
                        <div className='flex items-center pt-4'>
                          <input
                            type='checkbox'
                            id='checkbox1'
                            name='acceptTerms'
                            data-testid='acceptTerms'
                            aria-label='Click to accept terms and conditions'
                            required
                            checked={formData.termsAgreed}
                            value={formData.termsAgreed.toString()}
                            onChange={() =>
                              setFormData({
                                ...formData,
                                termsAgreed: !formData.termsAgreed,
                              })
                            }
                            className='form-checkbox w-8 h-8 mr-2 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500'
                          />
                          <label className='ml-2 block text-sm text-gray-900'>
                            <span className='text-sm'>
                              <FormattedMessage
                                id='ConsentMsg'
                                defaultMessage='Yes, I agree to'
                              />{' '}
                              <a
                                className='text-blue-800'
                                href='#terms'
                                aria-label='View terms and conditions.'
                                onClick={() =>
                                  openModal(
                                    'https://privacyportal-cdn.onetrust.com/b92d6cbd-0541-4263-b03e-4080c669a083/privacy-notices/a509f889-ddf7-4929-aab9-f8631d946c87.json',
                                  )
                                }>
                                <FormattedMessage
                                  id='Terms'
                                  defaultMessage='The Terms of Service'
                                />{' '}
                              </a>
                              <FormattedMessage id='And' defaultMessage='And' />{' '}
                              <a
                                className='text-blue-800'
                                href='#terms'
                                aria-label='View privacy policy.'
                                onClick={() =>
                                  openModal(
                                    'https://privacyportal-cdn.onetrust.com/b92d6cbd-0541-4263-b03e-4080c669a083/privacy-notices/992457b6-7514-439d-8f42-847cdbdd8326.json',
                                  )
                                }>
                                <FormattedMessage
                                  id='Privacy'
                                  defaultMessage='Privacy Policy'
                                />{' '}
                              </a>
                            </span>
                          </label>
                        </div>
                      </>
                    ) : (
                      <input
                        hidden
                        type='checkbox'
                        data-testid='acceptTerms'
                        name='acceptTerms'
                        defaultChecked
                        value={formData.termsAgreed.toString()}
                      />
                    )}
                  </div>
                </div>
                <input
                  type='hidden'
                  name='subscription_source'
                  value={formData.subscription_source}
                />
                <input
                  type='hidden'
                  name='session_token'
                  value={formData.session_token}
                />
                <input type='hidden' name='state' value={formData.state} />
                <div className='flex justify-center items-center'>
                  <button
                    type='submit'
                    data-testid='continueButton'
                    disabled={errorMessage !== null ? true : false}
                    aria-label='Click to submit the form. All fields are required to continue.'
                    className='w-3/4 m-10 p-5 flex items-center justify-center relative px-4 py-1 min-h-14 cursor-pointer text-white border-0 rounded-md bg-b-blue my-4 tracking-wider text-base leading-6'>
                    <FormattedMessage id='Continue' defaultMessage='Continue' />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      ) : (
        <TermsModal isOpen={isOpen} setIsOpen={setIsOpen} />
      )}
    </>
  );
}

export default App;
